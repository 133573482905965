import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import NodesService from '../services/nodes';

import { withAuthenticator } from '@aws-amplify/ui-react';

const NodeDetail = ({ user }) => {
    const [node, setNode] = useState([]);
    const dataFetchedRef = useRef(false);
    const params = useParams();
    const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

    async function getNodeDetails() {
        NodesService.getNode(user, params.id).then(result => {
            if(result.status === "200 OK"){
                // console.log("ok", result);
                setNode(result.data.node);
            } else {
                setNode();
                console.log('Fetch error: ', result.status);
            }
        });
    }

    useEffect(() => {
        //we use useRef to keep track of a boolean. 
        //If it’s true, we’ve already been here, and we can return. 
        //If it is false, we can perform our fetch and then update the ref.
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getNodeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <Header>
                <Link onClick={goBack}><Image src={'/arrow_left.svg'} alt="arrow left" /> terug naar project</Link>
            </Header>
            <div>
                <Node>
                    <h2>{node.name}</h2><br/>
                    <p>Model: {node.model}</p>
                    <p>Device ID: {node.device_id}</p>
                    <p>Serial: {node.serial}</p>
                </Node>
            </div>
        </Wrapper>
    )
};

const Wrapper = styled.main`
  padding: 20px 40px 80px 30px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 30px;
    justify-content: space-between;
`;

const Node = styled.div`
    margin: 24px;
`;

const Image = styled.img`
    padding-top: 0.5rem;
    height: 16px;
  
`;

export default withAuthenticator(NodeDetail);