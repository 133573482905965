import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import Users from '../services/users';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import styled, { createGlobalStyle } from "styled-components";
import { Home, Grid, List, LogOut } from "react-feather";

const Layout = ({user}) => {
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
      setWindowDimension(window.innerWidth);
    }, []);
  
    useEffect(() => {
      function handleResize() {
        setWindowDimension(window.innerWidth);
      }
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const isMobile = windowDimension <= 640;

    async function signOut() {
        //set logged_in boolean in user table to false
        Users.verifyUser(user, "SIGNOUT").then(result => {
        console.log("ok", result);
        });

        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <>
            <Styles.Wrapper>
            <CSSReset />

            {isMobile ? (
                <>
                <MobileImage src={'/logo_rainup-pro_small.svg'} alt="rainup pro logo" />
                <MobileNavbar.Wrapper>
                <MobileNavbar.Items>
                    {/* <MobileNavbar.Item>
                        <Link to="/"><MobileNavbar.Icon><Home size={16} /></MobileNavbar.Icon></Link>
                        <Link to="/">Dashboard</Link>
                    </MobileNavbar.Item> */}
                    <MobileNavbar.Itemmx><Link to="/projects"><MobileNavbar.Icon><Grid size={20} color={'white'} /></MobileNavbar.Icon></Link></MobileNavbar.Itemmx>
                    <MobileNavbar.Item><Button onClick={signOut}><MobileNavbar.Icon><LogOut size={20} /></MobileNavbar.Icon></Button></MobileNavbar.Item>
                </MobileNavbar.Items>
                </MobileNavbar.Wrapper>
                </>
            ) : (
                <Navbar.Wrapper>
                <Navbar.Logo><Image src={'/logo-rainup-pro_v2.svg'} alt="rainup pro logo" /></Navbar.Logo>
                <Navbar.Items>
                    <Navbar.Itemx><Link to="/projects"><MobileNavbar.Icon><Grid size={20} color={'white'} /></MobileNavbar.Icon></Link></Navbar.Itemx>
                    <Navbar.Item><Button onClick={signOut}><MobileNavbar.Icon><LogOut size={20} /></MobileNavbar.Icon></Button></Navbar.Item>
                </Navbar.Items>
                </Navbar.Wrapper>
            )}
            </Styles.Wrapper>
            <Outlet />
        </>
    )
};

const Styles = {
    Wrapper: styled.main`
        display: flex;
    `,
};

const Button = styled.button`
    border: none;
    background: none;
`;

const Image = styled.img`
    padding-top: 0.5rem;
    height: 35px;
`
const MobileImage = styled.img`
    padding: 0rem 3rem;
    height: 50px;
    margin-top: 2.5rem;
`

const CSSReset = createGlobalStyle`
    *,
    *::before, 
    *::after {
        margin: 0; 
        padding: 0;
        box-sizing: inherit;
    }

    html {
        font-size: 62.5%; /*1rem = 10px*/
        box-sizing: border-box;    
    }  

    body {
        font-size: 1.4rem;
        font-family: Verdana, sans-serif;  
    }
`;

const Navbar = {
    Wrapper: styled.nav`
      flex: 1;
  
      align-self: flex-start;
  
      padding: 1rem 3rem;
  
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      background-color: #E1E4E5;
    `,
    Logo: styled.h1`
    //   border: 1px solid gray;
    //   padding: 0.5rem 1rem;
    `,
    Items: styled.ul`
      display: flex;
      list-style: none;
    `,
    Itemx: styled.li`
      cursor: pointer;
      font-weight: bold;
      padding: 14px;
      background: #EB5A37;
      margin-right: 5px;
      border-radius: 4px;
    `,
    Item: styled.li`
      cursor: pointer;
      font-weight: bold;
      padding: 14px;
      background: #fff;
      margin-right: 5px;
      border-radius: 4px;
    `,
  };

const MobileNavbar = {
    Wrapper: styled(Navbar.Wrapper)`
        position: fixed;
        width: 100vw;
        bottom: 0;
        background: #E0E4E5;
        justify-content: center;
    `,
    Items: styled(Navbar.Items)`
        flex: 1;
        padding: 0 2rem;

        justify-content: space-around;
    `,
    Itemmx: styled(Navbar.Itemx)`
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 1.2rem;
    font-weight: bold;
    `,
    Item: styled(Navbar.Item)`
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 1.2rem;
    font-weight: bold;
    `,
    Icon: styled.span``,
};

export default withAuthenticator(Layout);