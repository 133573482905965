import React from 'react';
import styled from "styled-components";
// import ActivityRings, {ActivityRingsConfig, ActivityRingsData} from "react-activity-rings"

const Home = () => {

  // const activityData = [
  //   {
  //     label: "TEST",
  //     value: 0.8, // ring will use color from theme
  //     color: "#03A9F4",
  //     // backgroundColor: "#BDBDBD"
  //   },
  //   {
  //     label: "ACTIVITY",
  //     value: 0.6,
  //     // color: "#cb5f18",
  //   },
  //   // {
  //   //   label: "RINGS",
  //   //   value: 0.2,
  //   //   // color: "#86040f",
  //   //   // backgroundColor: "#cccccc"
  //   // }
  // ];
 
  // const activityConfig = { 
  //   width: 150,  
  //   height: 150,
  //   radius: 32,
  //   ringSize: 14
  // }

    return (
        <>
        <Wrapper>
            <h1>Welkom bij Rainup pro</h1><br/>
            <p>Dit is de beginscherm van de zakelijke omgeving. Hier kunnen we bepalen of en welke info we willen tonen.</p>
            <Test>
            <ul>
              <li>Is dit land specifiek?</li>
              <li>Tonen we nieuws items vanuit Rainup?</li>
              <li>Tonen we overzicht van het aantal projecten / hoeveelheid waterberging van de projecten?</li>
            </ul>
            </Test>
            <br/>
            {/* <ActivityRings legend={true} legendTitle={"Awesome"} theme={"light"} data={activityData} config={activityConfig} /> */}
        </Wrapper>
        </>
    );
  };
  

const Wrapper = styled.main`
  padding: 2em;
`;

const Test = styled.main`
  margin: 10px 0 0 15px;
`;

export default Home;