import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "../src/components/ScrollToTop";
import UserService from './services/users';
import { Auth } from 'aws-amplify';
import styled from "styled-components";
// import logo from "./logo.svg";
import './App.css';
import "@aws-amplify/ui-react/styles.css";
import { withAuthenticator } from '@aws-amplify/ui-react';
// import {
//   withAuthenticator,
//   Button,
//   Heading,
//   Image,
//   View,
//   Card,
// } from "@aws-amplify/ui-react";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import ProjectNew from './pages/ProjectNew';
import ProjectDetails from './pages/ProjectDetails';
import NodeDetails from './pages/NodeDetails';
import Nodes from './pages/Nodes';
import NoPage from "./pages/NoPage";
import Maps from "./pages/Maps";
import { MobileContext } from "../src/components/MobileContext";
import GlobalFonts from './fonts/fonts';

function App({ user }) {
  const [windowDimension, setWindowDimension] = useState(null);
  const [verified, setVerified] = useState(false);
  const dataFetchedRef = useRef(false);
  
  async function updateUser() {
    UserService.verifyUser(user, "SIGNIN").then(result => {
    if(result.status === "200 OK"){
        // console.log("ok", result);
        setVerified(true);
    } else {
        console.log('Fetch error: ', result.status);
    }
    });
  }

  useEffect(() => {
    setWindowDimension(window.innerWidth);

    if (user.attributes.sub) {
      //we use useRef to keep track of a boolean. 
      //If it’s true, we’ve already been here, and we can return. 
      //If it is false, we can perform our fetch and then update the ref.
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      updateUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleResize() {
        setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  async function signOut() {
    //set logged_in boolean in user table to false
    UserService.verifyUser(user, "SIGNOUT").then(result => {
    console.log("ok", result);
    });

    try {
        await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

  const isMobile = windowDimension <= 640;

  return (
    <>
    <GlobalFonts />
    {!verified ? (
        <Center>
          Je bent niet geautoriseerd voor Rainup PRO<br/><br/>
          <Button onClick={signOut}>Sign out</Button>
        </Center>
    ) : (
        <>
            <BrowserRouter>
              <MobileContext.Provider value={isMobile}>
                <ScrollToTop />
                <Routes>
                  <Route path="/" element={<Layout />}>
                    <Route index element={<Projects />} />
                    <Route path="projects" element={<Projects />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="maps" element={<Maps />} />
                    <Route path="project-new" element={<ProjectNew />} />
                    <Route path="project-details/:id" element={<ProjectDetails />} />
                    <Route path="nodes/:id" element={<Nodes />} />
                    <Route path="node-details/:id" element={<NodeDetails />} />
                    <Route path="*" element={<NoPage />} />
                  </Route>
                </Routes>
              </MobileContext.Provider>
            </BrowserRouter>
        </>
    )}
    </>
  );
}

export default withAuthenticator(App, {
  // hideSignUp: true
});

const Button = styled.button`
  min-width: 50px;
  background: #1976D2;
  border-radius: 8px;
  padding: 0 20px;
  border: none;
  border-bottom: 1px solid #2A67D2;
  color: #fff;
`;

const Center = styled.div`
  padding: 70px 0;
  text-align: center;
`