import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import ProjectService from '../services/projects';
import { MobileContext } from '../components/MobileContext';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '../App.css';

const Projects = ({ user }) => {
    const [projects, setProjects] = useState([]);
    const dataFetchedRef = useRef(false);
    const isMobile = useContext(MobileContext);
    const [numberOfProjects, setNumberOfProjects] = useState();
    const [available, setAvailable] = useState();
    const [percentage, setPercentage] = useState();
    const [thousand, setThousand] = useState();

    async function getProject() {
        ProjectService.getProject(user).then(result => {
            if(result.status === "200 OK"){
                // console.log("projects", result);
                setProjects(result.data);
                setNumberOfProjects(result.data.length);
                setAvailable(result.liters.available);
                setPercentage(result.liters.percentage);
                setThousand(result.liters.thousand);
            } else {
                setProjects();
                console.log('Fetch error: ', result.status);
            }
        });
    }

    useEffect(() => {
        //we use useRef to keep track of a boolean. 
        //If it’s true, we’ve already been here, and we can return. 
        //If it is false, we can perform our fetch and then update the ref.
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getProject();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="Wrapper">
            <Header>
            <Title>mijn projecten</Title>
            <Summary><Number>{numberOfProjects}</Number><Text>projecten</Text></Summary>
            <Summary><Number>{percentage}%</Number><Text>gevuld</Text></Summary>
            <Summary><Number>{available}{thousand &&<>k.</>}</Number><Text>liter beschikbaar</Text></Summary>
            {/* <Button><Link to="/project-new"><p style={{color:"#fff"}}>+ Nieuw project</p></Link></Button> */}
            {/* <Button3>
                {!isMobile && <Link to="/maps"><i className="fa-solid fa-location-dot"></i> Kaart</Link>}
                {isMobile && <Link to="/maps"><i className="fa-solid fa-location-dot fa-xl"></i></Link>}
            </Button3> */}
            </Header>
            
            <div>
                {!projects ? (
                    <div>Er zijn geen projecten geregistreerd</div>
                ) : (
                    <>
                    <Cards>
                        {projects.map((project) => (
                            <div key={project.id}>
                                <Link to={`/project-details/${project.id}`} state={{ project }}>
                                <Card>
                                        <Text>{project.name}</Text>
                                        <Sensors>{project.nodes.error ? <Red /> : <Green />}{project.nodes.up}/{project.nodes.count} sensor is up</Sensors><br/>
                                        <Icons>
                                            {project.nodes.average_vwc && <Icon><Image src={'/drop.svg'} alt="drop icon" /> {project.nodes.average_vwc}%</Icon>}
                                            {project.nodes.average_level && <Icon><Image src={'/level.svg'} alt="level icon" /> {project.nodes.average_level}mm</Icon>}
                                        </Icons>
                                        {/* <p>Postcode: {project.postal_code}</p><br/> */}
                                        {/* <p>Omschrijving:<br/>{project.description}</p><br/> */}
                                        {/* <Button2><Link to={`/project-details/${project.id}`}  state={{ project }}>+ details</Link></Button2> */}
                                </Card>
                                </Link>
                            </div>
                        ))}
                    </Cards>
                    </>
                )}
            </div>
        </div>
    )
};

// const Wrapper = styled.main`
//     padding: 3em 2em;
// `;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 30px;
    justify-content: space-between;
    font-family: 'SuisseIntl';
`;

const Summary = styled.div`
    box-sizing: border-box;
    width: 150px;
`;

const Number = styled.div`
    font-size: 48px;
    font-weight: bold;
    border-bottom: 1px solid #000000;
`;

const Text = styled.p`
    margin-top: 8px;
    font-size: 18px;
`;

const Title = styled.div`
    font-size: 32px;
    margin-right: 50px;
`;

const Sensors = styled.div`
    display:flex; 
    flex-direction: row; 
    // justify-content: center; 
    // align-items: center"
`;

const Green = styled.p`
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #00C537;
    margin-right: 5px;
    margin-top: 6px;
`;

const Red = styled.p`
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #EE1414;
    margin-right: 5px;
    margin-top: 6px;
`;

const Button = styled.button`
    min-width: 50px;
    background: #1976D2;
    border-radius: 6px;
    padding: 0 20px;
    border: none;
    border-bottom: 1px solid #2A67D2;
    color: #fff;
`;

const Button2 = styled.button`
    min-width: 50px;
    border-radius: 6px;
    padding: 6px 12px;
    border: none;
    border: 1px solid #000;
    background: #fff;
    margin-top: 20px;
`;

const Button3 = styled.button`
    min-width: 50px;
    border-radius: 6px;
    padding: 6px 12px;
    border: none;
    border: 1px solid #000;
    background: #fff;
`;

const Cards = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;

 `;

 const Card = styled.div`
    background:#fff;
    // display: flex;
    // border: 1px solid #eeeeee;
    border-radius: 8px;
    // box-sizing: border-box;
    margin: 1rem .25em;
    // padding: 20px 60px 20px 20px;
    padding: 20px;
    width: 280px;
    
    @media screen and (max-width: 640px) {
        flex: 0 1 calc(100%);
        width: 300px;
    }
 `;

 const Icons = styled.div`
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
`;

const Icon = styled.div`
 background:#fff;
 box-sizing: border-box;
 padding-right: 22px;
`;

const Image = styled.img`
    padding-top: 0.5rem;
    height: 16px;
    margin-right: 6px;
`
export default withAuthenticator(Projects);